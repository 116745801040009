<template>
  <div class="section_goods">
    <div class="info">주문정보</div>
    <div class="info_detail" v-if="orderData.orders.deposit_yn === 'n'">
      <b>주문접수</b>&nbsp;&nbsp;입금대기
    </div>
    <div class="info_detail" v-else>
      <b>주문일자</b>&nbsp;&nbsp;{{ day(orderData.orders.deposit_date) }} 결제
    </div>
    <div class="info_detail">
      <b>주문번호</b>&nbsp;&nbsp;{{ orderData.orders.order_seq }}
    </div>
    <div class="info add_line">
      주문 취소 가능 상품
      <div class="check_all">
        <label
          ><input
            type="checkbox"
            name=""
            v-model="isLocalAllCheck"
            :disabled="isDisabled"
            @change="allCheck($event)"
          />상품 모두 선택</label
        >
      </div>
    </div>
    <div v-for="(item, index) in itemList" class="goods" :key="index">
      <div class="goods_seq">
        <label
          ><input
            type="checkbox"
            name=""
            v-model="item.checked"
            :data-seq="item.goods_seq"
            :data-item="item.item_seq"
            :data-otion="item.item_option_seq"
            :disabled="isDisabled"
            @change="checkBoxCheck($event, index)"
          />
          상품 번호 {{ item.goods_seq }}{{ item.item_seq
          }}{{ item.item_option_seq }}</label
        >
      </div>
      <div class="img">
        <img :src="item.image" @error="replaceImg" :alt="item.goods_name" />
      </div>
      <div class="txt">
        <h3>{{ item.goods_name }}</h3>
        <!-- <h4>
          {{
            won(parseInt(item.sale_price) * parseInt(item.ea ? item.ea : 0))
          }}원
          <span
            v-if="
              parseInt(item.sale_price) * parseInt(item.ea ? item.ea : 0) <
              parseInt(item.out_price) * parseInt(item.ea ? item.ea : 0)
            "
          >
            {{
              won(parseInt(item.out_price) * parseInt(item.ea ? item.ea : 0))
            }}원
          </span>
        </h4> -->
        <h4>
          {{ won(parseInt(item.sale_price)) }}원
          <span v-if="parseInt(item.sale_price) < parseInt(item.out_price)">
            {{ won(parseInt(item.out_price)) }}원
          </span>
        </h4>
        <p class="shipping_txt">(배송비 제외)</p>
        <p v-if="item.title1">{{ item.title1 }} : {{ item.option1 }}</p>
        <p v-if="item.title2">{{ item.title2 }} : {{ item.option2 }}</p>
        <p v-if="item.title3">{{ item.title3 }} : {{ item.option3 }}</p>
        <p v-if="item.title4">{{ item.title4 }} : {{ item.option4 }}</p>
        <p v-if="item.title5">{{ item.title5 }} : {{ item.option5 }}</p>
        <p v-for="(sub, index) in item.suboptions" :key="index">
          추가 : {{ sub.title }}({{ sub.suboption }})
          <!-- {{ won(parseInt(sub.price) * parseInt(sub.ea ? sub.ea : 0)) }}원 -->
          {{ won(parseInt(sub.price)) }}원 {{ sub.ea ? sub.ea : 0 }}개
        </p>
        <p v-if="parseInt(item.provider_seq) === 1">
          <span class="left">{{ item.provider_name }}</span
          ><span class="right"
            ><router-link :to="`/mypage/order_detail/${orderSeq}`"
              >주문상세 &gt;</router-link
            ></span
          >
        </p>
        <p v-else>
          <span class="left"
            >{{ item.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span
          ><span class="right"
            ><router-link :to="`/mypage/order_detail/${orderSeq}`"
              >주문상세 &gt;</router-link
            ></span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      inputCheck: false,
      itemListArray: [],
    };
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    orderSeq: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    isLocalAllCheck: {
      get() {
        return this.checked || parseInt(this.orderData.orders.step) === 15
          ? true
          : false;
      },
      set(check) {
        this.checked = check;
      },
    },
    isDisabled() {
      return parseInt(this.orderData.orders.step) === 15;
    },
    itemList() {
      if (this.orderData.shipping_group_items) {
        Object.keys(this.orderData.shipping_group_items).forEach((key) => {
          this.orderData.shipping_group_items[key].items.forEach((item) => {
            item.options.forEach((option) => {
              if (
                parseInt(option.step) === 15 ||
                parseInt(option.step) === 25
              ) {
                let data = {};
                data.checked = parseInt(option.step) === 15;
                data.provider_seq = item.provider_seq;
                data.provider_name = item.provider_name;
                data.image = item.image;
                data.ea = option.ea;
                data.goods_name = item.goods_name;
                data.goods_seq = item.goods_seq;
                data.item_option_seq = option.item_option_seq;
                data.item_seq = option.item_seq;
                data.title1 = option.title1;
                data.title2 = option.title2;
                data.title3 = option.title3;
                data.title4 = option.title4;
                data.title5 = option.title5;
                data.option1 = option.option1;
                data.option2 = option.option2;
                data.option3 = option.option3;
                data.option4 = option.option4;
                data.option5 = option.option5;
                data.out_price = option.out_price;
                data.sale_price = option.sale_price;
                let price = 0;
                let arrays = [];
                if (option.suboptions) {
                  Object.keys(option.suboptions).forEach((key) => {
                    price +=
                      parseInt(option.suboptions[key].price) *
                      parseInt(
                        option.suboptions[key].ea
                          ? option.suboptions[key].ea
                          : 0
                      );
                    arrays.push(option.suboptions[key]);
                  });
                }
                data.suboptions = arrays;
                data.addPrice = price;
                this.itemListArray.push(data);
              }
            });
          });
        });
      }
      return this.itemListArray;
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    won(str) {
      return this.$won(str);
    },
    day(str) {
      return this.$moment(str).format("YYYY-MM-DD");
    },
    allCheck(e) {
      if (this.isLocalAllCheck) {
        e.target.checked = true;
        this.itemList.forEach((item) => {
          item.checked = true;
        });
      } else {
        e.target.checked = false;
        this.itemList.forEach((item) => {
          item.checked = false;
        });
      }
    },
    checkBoxCheck(e, index) {
      this.isLocalAllCheck = true;
      this.itemList[index].checked = e.target.checked;
      this.itemList.forEach((item) => {
        if (!item.checked) {
          this.isLocalAllCheck = false;
        }
      });
    },
    init() {
      this.itemListArray = [];
      this.isLocalAllCheck = false;
      if (this.itemList.length <= 0) {
        this.$emit("closeOrderCalcel");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rn_my_box_21 .section_goods {
  margin-bottom: 0;
  border-bottom: none;
  .goods {
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
    .goods_seq {
      padding: 10px 0;
    }
    .txt {
      padding-top: 0;
      .shipping_txt {
        font-size: 11px;
        margin-bottom: 10px;
        line-height: 11px;
      }
      h4 {
        margin-bottom: 5px;
      }
      p {
        line-height: 18px;
      }
    }
  }
  .info_detail {
    width: 100%;
    overflow: hidden;
    line-height: 16px;
    padding: 5px 0;
  }
  .info_detail:nth-child(3) {
    padding: 5px 0 15px 0;
  }
  .info {
    width: 100%;
    overflow: hidden;
    line-height: 16px;
    padding: 12px 0;
    background: #f7f9fa;
    font-weight: 600;
    &.add_line {
      border-top: 1px solid #cccccc;
    }
    .check_all {
      font-weight: 600;
      line-height: 15px;
      float: right;
      input[type="checkbox"] {
        margin-right: 5px;
      }
    }
  }
}
</style>
