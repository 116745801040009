<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      주문 취소하기<router-link to="" @click.native="closeModal(pageInfo)"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_21">
      <div class="top">
        <ul>
          <li>[주문 취소]는 상품이 발송되기 전에만 가능합니다.</li>
          <li v-if="parseInt(orderData.orders.step) === 15">
            아래 상품이 주문을 취소하려는 상품이 맞는지 확인하시고 맞으면
            [주문을 취소 하려는 상품이 맞습니다]를 눌러 주세요.
          </li>
          <li v-else>
            아래 상품이 주문을 취소하려는 상품이 맞는지 확인하시고 맞으면
            [주문을 취소하려는 상품이 맞습니다]를 누른 후 구체적인 사유를
            적어주세요.
          </li>
          <li v-if="parseInt(orderData.orders.step) === 15">
            [주문 취소 신청] 버튼을 눌러주세요.
          </li>
          <li v-else>
            모든 사항을 입력했으면 [주문 취소 신청] 버튼을 눌러주 세요.
          </li>
        </ul>
      </div>
      <OrderCancelGoods
        ref="orderCancelGoods"
        :orderData="orderData"
        :orderSeq="orderSeq"
        @closeOrderCalcel="closeOrderCalcel"
      />
      <div class="section_input">
        <div class="radio_box">
          <label
            ><input type="checkbox" name="" v-model="cancelCheck" /> 주문을 취소
            하려는 상품이 맞습니다.</label
          >
        </div>
        <div class="text_box" v-if="parseInt(orderData.orders.step) !== 15">
          <textarea
            name=""
            placeholder="구체적인 사유를 적어주세요.(100자 미만)"
            v-model="refundReason"
            maxlength="100"
          ></textarea>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div
      class="rn_my_box_btn yellow"
      v-if="orderData.orders.payment != 'pos_pay'"
    >
      <div v-if="parseInt(orderData.orders.step) === 15">
        <router-link to="" @click.native="orderCancel()"
          >주문 취소 신청</router-link
        >
      </div>
      <div
        v-if="
          orderData.refund_able_ea &&
          parseInt(orderData.orders.step) >= 25 &&
          parseInt(orderData.orders.step) < 85 &&
          !orderData.orders.orign_order_seq
        "
      >
        <router-link to="" @click.native="orderRefund()"
          >주문 취소 신청</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _find from "lodash/find";
import OrderCancelGoods from "@/components/mypage/order/OrderCancelGoods";
export default {
  props: {
    orderSeq: {
      type: String,
      default: "",
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // orderSeq: this.$route.params.order_seq,
      ticketStep: [40, 50, 55, 60, 70, 75],
      cancelCheck: false,
      refundReason: "",
    };
  },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getOrderView();
  },
  methods: {
    closeModal(pageInfo = null) {
      console.log(pageInfo);
      this.$emit("closeModal", "cancel", pageInfo);
    },
    async getOrderView() {
      await this.$store.dispatch("order/getOrderView", {
        order_seq: this.orderSeq,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
        //this.$router.push("/");
        this.closeModal(this.pageInfo);
        return false;
      }
      if (
        parseInt(this.orderData.orders.step) !== 15 &&
        !(
          this.orderData.refund_able_ea &&
          parseInt(this.orderData.orders.step) >= 25 &&
          parseInt(this.orderData.orders.step) < 85 &&
          !this.orderData.orders.orign_order_seq
        )
      ) {
        if (this.orderData.orders.orign_order_seq) {
          this.$toast.default("교환상품은 주문취소가 불가능합니다.");
        } else {
          this.$toast.default("주문취소를 할 수 없습니다.");
        }
        // console.log(
        //   this.orderData.orders.step,
        //   this.orderData.refund_able_ea,
        //   this.orderData.orders.orign_order_seq
        // );
        this.closeModal(this.pageInfo);
        return false;
      }
    },
    async orderCancel() {
      if (!this.cancelCheck) {
        this.$toast.default(
          "주문을 취소하려는 상품이 맞습니다. 를 체크 해주세요"
        );
        return false;
      }
      this.$confirm("해당 주문건을 주문 취소하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("order/orderCancel", {
            order_seq: this.orderData.orders.order_seq,
          });
          this.$toast.default(this.msg);
          if (this.result) {
            //this.$router.push("/mypage/my_info");
            this.closeModal(this.pageInfo);
          }
        })
        .catch(() => {
          return false;
        });
    },
    orderRefund() {
      let refundData = new FormData();

      refundData.append("order_seq", this.orderData.orders.order_seq);
      refundData.append("cancel_type", "full");

      if (
        this.orderData.pgCompany === "allat" &&
        this.orderData.orders.payment === "card"
      ) {
        refundData.append("allat_shop_id", this.orderData.pg.mallCode);
        refundData.append("allat_order_no", this.orderData.orders.order_seq);
        refundData.append("allat_amt", this.orderData.orders.settleprice);
        refundData.append(
          "allat_seq_no",
          this.orderData.orders.pg_transaction_number
        );
        refundData.append("allat_pay_type", "CARD");
        refundData.append("allat_enc_data", "");
        refundData.append("allat_opt_pin", "NOVIEW");
        refundData.append("allat_opt_mod", "WEB");
        refundData.append("allat_test_yn", "N");
      }
      if (this.orderData.pgCompany === "kspay") {
        refundData.append("storeid", this.orderData.pg.mallId);
        refundData.append("storepasswd", this.orderData.pg.mallPass);
        refundData.append("authty", this.orderData.orders.kspay_authty);
        refundData.append("trno", this.orderData.orders.pg_transaction_number);
      }
      const checkList = document.querySelectorAll(
        ".goods input[type='checkbox']:checked"
      );
      let goodsSeqList = [];
      let itemSeqList = [];
      let optionSeqList = [];
      [...checkList].map((list) => {
        goodsSeqList.push(list.dataset.seq);
        itemSeqList.push(list.dataset.item);
        optionSeqList.push(list.dataset.otion);
      });
      goodsSeqList.forEach((seq, idx) => {
        Object.keys(this.orderData.shipping_group_items).forEach((key) => {
          this.orderData.shipping_group_items[key].items.forEach((item) => {
            if (parseInt(seq) === parseInt(item.goods_seq)) {
              item.options.forEach((option) => {
                if (
                  parseInt(itemSeqList[idx]) === parseInt(option.item_seq) &&
                  parseInt(optionSeqList[idx]) ===
                    parseInt(option.item_option_seq) &&
                  parseInt(option.step) === 25
                ) {
                  refundData.append("chk_seq[]", 1);
                  refundData.append("chk_ea[]", option.ea);
                  refundData.append("chk_item_seq[]", option.item_seq);
                  refundData.append("chk_option_seq[]", option.item_option_seq);
                  refundData.append("chk_suboption_seq[]", "");
                  refundData.append(
                    "chk_individual_refund[]",
                    item.individual_refund
                  );
                  refundData.append(
                    "chk_individual_refund_inherit[]",
                    item.individual_refund_inherit
                  );
                  refundData.append(
                    "chk_individual_export[]",
                    item.individual_export
                  );
                  refundData.append(
                    "chk_individual_return[]",
                    item.individual_return
                  );
                  if (option.suboptions) {
                    Object.keys(option.suboptions).forEach((subKey) => {
                      if (option.suboptions[subKey]) {
                        const sub = option.suboptions[subKey];
                        refundData.append("chk_seq[]", 1);
                        refundData.append("chk_ea[]", sub.ea);
                        refundData.append("chk_item_seq[]", sub.item_seq);
                        refundData.append(
                          "chk_option_seq[]",
                          sub.item_option_seq
                        );
                        refundData.append(
                          "chk_suboption_seq[]",
                          sub.item_suboption_seq
                        );
                        refundData.append(
                          "chk_individual_refund[]",
                          item.individual_refund
                        );
                        refundData.append(
                          "chk_individual_refund_inherit[]",
                          item.individual_refund_inherit
                        );
                        refundData.append(
                          "chk_individual_export[]",
                          item.individual_export
                        );
                        refundData.append(
                          "chk_individual_return[]",
                          item.individual_return
                        );
                      }
                    });
                  }
                }
              });
            }
          });
        });
      });
      if ([...checkList].length <= 0) {
        this.$toast.default("주문을 취소할 상품을 선택 해주세요");
        return false;
      }
      if (!this.cancelCheck) {
        this.$toast.default(
          "주문을 취소하려는 상품이 맞습니다. 를 체크 해주세요"
        );
        return false;
      }
      if (this.refundReason.replace(/\s/g, "").length <= 0) {
        this.$toast.default("주문 취소사유를 입력해주세요");
        this.refundReason = "";
        return false;
      }
      refundData.append("refund_reason", this.refundReason);
      this.$confirm("해당 주문건을 주문 취소 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("order/orderRefund", refundData);
          this.$toast.default(this.msg);
          this.init();
        })
        .catch(() => {
          return false;
        });
    },
    init() {
      this.$refs.orderCancelGoods.init();
      this.cancelCheck = false;
      this.refundReason = "";
    },
    closeOrderCalcel() {
      this.$store.dispatch("order/orderDataInit");
      //this.$router.push("/mypage/my_info");
      this.closeModal(this.pageInfo);
    },
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg"]),
  },
  components: {
    OrderCancelGoods,
  },
};
</script>

<style></style>
